<template>
  <div />
</template>

<script>
import { defineComponent, onBeforeMount } from 'vue'
import ls from '@/utils/localstorage'
import localStoreKeys from '@/config/localStoreKeys'
import store from '@/store'
import { get } from 'lodash'
import { useRouter } from 'vue-router/composables'

/*
  此頁為跳轉用頁面
  主要是因為 changeShop 如果在 router.beforeEach 中執行會有無限跳轉的問題
  因為 changeShop 中會 resetRouter，而 resetRouter 會觸發 router.beforeEach
  所以必須等到 router.beforeEach 執行完畢後進到頁面再執行 changeShop
*/

export default defineComponent({
  name: 'Redirect',
  setup () {
    const router = useRouter()
    onBeforeMount(async () => {
      const lastRoute = ls.get(localStoreKeys.lastRoute)
      if (lastRoute) {
        const queryShopId = get(lastRoute, 'query.queryShopId')
        if (queryShopId) {
          const success = await store.dispatch('auth/changeShop', {
            shopId: queryShopId,
            message: window.$message,
          })
          if (!success) {
          //  TODO
          }
          await store.dispatch('app/setSidebarPage', 2)
        }

        ls.remove(localStoreKeys.lastRoute)

        return router.replace({ path: lastRoute.fullPath })
      }
    })
  },
})

</script>

<style lang="postcss" scoped>
</style>
